.mainContainer {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    margin-left: 80px;
    align-items: center;
}

.mapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
