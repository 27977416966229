.vehiclesContainer {
    background-color: #fff;
    padding: 32px 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
    min-height: 500px;
}
.tableContainer {
}

.vehiclesInnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
}

.leftContainer {
    display: flex;
    margin-right: auto;
    margin-left: 80px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #241c2d;
}
.vehiclesBottomContainer {
    margin-left: 80px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.vehicleContainer {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #241c2d;
    line-break: normal;
    background-color: #f3f1fc;
    width: 164px;
    margin: 16px;
    margin-right: 32px;
    padding: 24px;
    border-radius: 12px;
    min-height: 216px;
}

.vehicleContainer p {
    padding: 4px;
}
