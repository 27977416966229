.deleteIcon {
    color: #c4314b;
    margin-left: 16px;
}

.deleteIcon:hover {
    cursor: pointer;
    color: #3b0e16;
}
.tableRow {
}
.tableRow a {
    margin-left: 8px;
    color: #237b4b;
    text-decoration: none;
}
.tableRow a:hover {
    color: #113a23;
}
