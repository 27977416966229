.tableContainer {
    background-color: #fff;
    padding: 32px 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    display: flex;
    flex-direction: column;

    margin-top: 32px;
    width: 100%;
    min-height: 500px;
}

.tableInnerTopContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px;
}

.leftContainer {
    display: flex;
    margin-right: auto;
    margin-left: 80px;
}

.rightContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 80px;
    text-decoration: none;
    color: #241c2d;
}

.tableInnerTopContainer p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #241c2d;
    margin-right: 8px;
}

.vehicleNumber {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}
