.table {
    border-collapse: separate;
    border-spacing: 16px;
    /* display: block;
    overflow-x: auto;
    white-space: pre-wrap; */
}

.table th {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    text-align: left;
}

.table td {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #241c2d;
}

.table tbody {
}
