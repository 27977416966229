.header {
    display: flex;
    background-color: #fff;
    height: 80px;
}

.logoBlack {
    margin-left: 80px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
}

.links {
    display: flex;
    align-items: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;

    margin-right: auto;
}

.links li {
    margin-left: 16px;
}

.links a {
    color: #b0b0b0;
    text-decoration: none;
}
.links a:hover {
    color: #241c2d;
}

.loggedUser {
    margin-right: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #241c2d;
}
