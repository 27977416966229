.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95%;
    align-items: center;
    justify-content: center;
}
.form div {
    width: 45%;
    margin: 8px;
    display: flex;
    flex-direction: column;
}

.form input {
    padding-left: 8px;
    border-radius: 10px;
    height: 32px;
    border: 1px solid #f6f6f6;
    background-color: #f6f6f6;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.08);

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}

.form input:focus {
    outline: 1px solid #cccccc;
}

.form label {
    padding-left: 8px;
    margin-bottom: 8px;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #241c2d;
}

.buttonContainer {
    padding-top: 16px;
}

.formButton {
    padding-top: 16px;
    padding-bottom: 16px;

    background: linear-gradient(90deg, #54d6d4 0%, #1f8a88 100%);
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.05);
    border-radius: 33px;
    border: none;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.105991px;
    text-transform: uppercase;

    color: #ffffff;
}

.formButton:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #4cc2c0 0%, #1b7776 100%);
}
