.formContainer {
    background-color: #fff;
    padding-top: 48px;
    padding-bottom: 48px;
    margin: 32px;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
